import 'zone.js/plugins/zone-error';
import { IEnvironment } from '@am/_types';
import { IBaseEnvironment } from '@arturin/core';

const backEndUrl = 'https://be-andrey-isaev.arturhaunt.com';

export const baseEnvironment: IBaseEnvironment = {
  accessManagerUrl: 'https://login-andrey-isaev.arturhaunt.com',
  baseDomain: 'arturhaunt.com',
  backEndUrl,
  successBackOfficeUrl: backEndUrl,
  enableAngularProdMode: false,
};

export const environment: IEnvironment = {
  ...baseEnvironment,
  apiUrl: `${baseEnvironment.backEndUrl}/`,
  recaptchaKey: '6Ldc1bcUAAAAANC5QFxbf8WbQmXhF5-L7U_XMKu3',
};
